import axios from "axios";
import {
  GET_PLACE_DETAILS_BY_URL_KEY,
  GET_INVESTORS,
  GET_IN_TOUCH,
  REQUEST_CALLBACK,
  REQUEST_QUOTE,
  GET_CMS_PAGES,
  GET_MEDIA,
  CONTACT_US,
  VIEW_PRESS_RELEASE,
  VIEW_ANNOCEMNT,
  SCHEDULE_MEETING,
  URL_RESOLVER,
  GET_AWARDS_AND_ACCOLADES,
  GET_CSR_GALLERY,
  GET_BOARD_OF_DIRECTORS_DATA,
  GET_LEADERSHIP_TEAM_DATA,
  GET_LATEST_NEWS,
  PREMIUM_SALES_PRODUCT_LIST,
  STORE_LOCATOR_SEND_SMS,
  TILE_SELECTOR,
  GETPRODUCTBYSKU,
  GET_STORE_DATA,
  GETSTATESCITIES,
  GETNONOBTB,
} from "../gql/others";
import client from "../graphqlClient";
import config from "../config";
import { isMobile } from "react-device-detect";
import { getBrowserName } from "../gtag";

export let savePincode = async (variables) => {
  try {
    let response = await axios.post(
      `${config.PROXY_SERVER_ENDPOINT}/rest/V1/pincodedetails`,
      variables
    );
    return response;
  } catch (error) {
    return error;
  }
};
export let requestCallback = async (variables) => {
  let options = {
    mutation: REQUEST_CALLBACK,
    variables: {
      ...variables,
      page_url: (JSON.parse(localStorage.getItem("visitedPages")) || [])
        .slice(-10)
        .join(" => "),
      First_Visited_URL: JSON.parse(localStorage.getItem("firstVistedPage")),
      User_Browser: getBrowserName(),
      User_Device: isMobile ? "Mobile" : "Desktop",
    },
  };

  try {
    let response = await client.mutate(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let getIntouch = async (variables) => {
  let options = {
    mutation: GET_IN_TOUCH,
    variables: {
      ...variables,
      page_url: (JSON.parse(localStorage.getItem("visitedPages")) || [])
        .slice(-10)
        .join(" => "),
      First_Visited_URL: JSON.parse(localStorage.getItem("firstVistedPage")),
      call_id: variables.call_id ? variables.call_id : "Get In Touch",
      User_Browser: getBrowserName(),
      User_Device: isMobile ? "Mobile" : "Desktop",
    },
  };

  try {
    let response = await client.mutate(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let requestQuote = async (variables) => {
  let options = {
    mutation: REQUEST_QUOTE,
    variables: {
      ...variables,
      User_Browser: getBrowserName(),
      User_Device: isMobile ? "Mobile" : "Desktop",
    },
  };

  try {
    let response = await client.mutate(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let getInvestors = async (variables) => {
  let options = {
    query: GET_INVESTORS,
    variables: variables,
  };

  try {
    let response = await client.query(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let GetCmsPages = async (variables) => {
  let options = {
    query: GET_CMS_PAGES,
    variables: variables,
  };

  try {
    let response = await client.query(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let getMedia = async (variables) => {
  let options = {
    query: GET_MEDIA,
    variables: variables,
    fetchPolicy: "no-cache",
  };

  try {
    let response = await client.query(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let getLatestNews = async (variables) => {
  let options = {
    query: GET_LATEST_NEWS,
    variables: variables,
    fetchPolicy: "no-cache",
  };

  try {
    let response = await client.query(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let contactUs = async (variables) => {
  let options = {
    mutation: CONTACT_US,
    variables: {
      ...variables,
      pageurl: (JSON.parse(localStorage.getItem("visitedPages")) || [])
        .slice(-10)
        .join(" => "),
      First_Visited_URL: JSON.parse(localStorage.getItem("firstVistedPage")),
      User_Browser: getBrowserName(),
      User_Device: isMobile ? "Mobile" : "Desktop",
    },
  };
  try {
    let response = await client.mutate(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let viewPressRelease = async (variables) => {
  let options = {
    query: VIEW_PRESS_RELEASE,
    variables: variables,
  };

  try {
    let response = await client.query(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let viewAnnoucement = async (variables) => {
  let options = {
    query: VIEW_ANNOCEMNT,
    variables: variables,
  };

  try {
    let response = await client.query(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let scheduleMeeting = async (variables) => {
  let options = {
    mutation: SCHEDULE_MEETING,
    variables: {
      ...variables,
      page_url: (JSON.parse(localStorage.getItem("visitedPages")) || [])
        .slice(-10)
        .join(" => "),
      First_Visited_URL: JSON.parse(localStorage.getItem("firstVistedPage")),
      User_Browser: getBrowserName(),
      User_Device: isMobile ? "Mobile" : "Desktop",
    },
  };

  try {
    let response = await client.mutate(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let urlResolver = async (variables) => {
  let options = {
    query: URL_RESOLVER,
    variables: variables,
  };

  try {
    let response = await client.query(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let getAwardsAndAccolades = async (variables) => {
  let options = {
    query: GET_AWARDS_AND_ACCOLADES,
    variables: variables,
  };
  try {
    let response = await client.query(options);
    return response;
  } catch (err) {
    return err;
  }
};

export let getCsrGallery = async (variables) => {
  let options = {
    query: GET_CSR_GALLERY,
    variables: variables,
  };
  try {
    let response = await client.query(options);
    return response;
  } catch (err) {
    return err;
  }
};

export let getBoardOfDirectorsData = async (variables) => {
  let options = {
    query: GET_BOARD_OF_DIRECTORS_DATA,
    variables: variables,
  };
  try {
    let response = await client.query(options);
    return response;
  } catch (err) {
    return err;
  }
};

export let getLeadershipTeamData = async (variables) => {
  let options = {
    query: GET_LEADERSHIP_TEAM_DATA,
    variables: variables,
  };
  try {
    let response = await client.query(options);
    return response;
  } catch (err) {
    return err;
  }
};

export let getPremiumSalesListing = async (variables) => {
  let options = {
    mutation: PREMIUM_SALES_PRODUCT_LIST,
    variables: variables,
  };

  try {
    let response = await client.mutate(options);
    return response;
  } catch (error) {
    return error;
  }
};

export let storeLocatorSendSms = async (variables) => {
  let options = {
    mutation: STORE_LOCATOR_SEND_SMS,
    variables: {
      ...variables,
      User_Browser: getBrowserName(),
      User_Device: isMobile ? "Mobile" : "Desktop",
    },
  };

  try {
    let response = await client.mutate(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let TileSelectorApi = async (variables) => {
  let options = {
    mutation: TILE_SELECTOR,
    variables: variables,
    fetchPolicy: "no-cache",
  };

  try {
    let response = await client.mutate(options);
    return response;
  } catch (error) {
    return error;
  }
};
export let getProductDetailsBySkuQuery = async (variables) => {
  let options = {
    mutation: GETPRODUCTBYSKU,
    variables: variables,
    fetchPolicy: "no-cache",
  };

  try {
    let response = await client.mutate(options);
    return response;
  } catch (error) {
    return error;
  }
};

export let GetStoreData = async (variables) => {
  let options = {
    query: GET_STORE_DATA,
    variables: variables,
  };
  try {
    let response = await client.query(options);
    return response;
  } catch (err) {
    return err;
  }
};
export let getStatesCities = async (variables) =>{
  let options = {
    query : GETSTATESCITIES,
    variables: variables,
  }
  try {
    let response = await client.query(options);
    return response;
  } catch (error) {
    return error
  }
}

export let getNonObtb = async (variables) =>{
  let options = {
    query : GETNONOBTB,
    variables: variables,
  }
  try {
    let response = await client.query(options);
    return response;
  } catch (error) {
    return error
  }
}

export let GetPlaceDetailsByUrlKey = async (variables) =>{
  let options={
  query:GET_PLACE_DETAILS_BY_URL_KEY,
  variables: variables,
  };
  try{
  let response= await client.query(options);
  return response;
  }catch(err){
  return err;
  }
  }